import type * as pt from "@prismicio/types"
import { getGatsbyImageData } from "@imgix/gatsby"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyRideOptionDetails } from "../../../slices/PageDataBodyRideOptionDetails"

const stat = () => ({
	heading: lorem.words(2),
	subheading: [
		{ type: "paragraph", spans: [], text: lorem.words(1) },
	] as pt.RichTextField,
})

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyRideOptionDetails
				image={getGatsbyImageData({
					src: "https://images.unsplash.com/photo-1602148740250-0a4750e232e9",
					width: 1000,
					layout: "fullWidth",
					aspectRatio: 890 / 594,
				})}
				imageAlt=""
				heading={lorem.words(4)}
				buttonOneLink="#"
				buttonOneText="Stay Connected"
				buttonTwoLink="#"
				buttonTwoText="Learn More"
				stats={Array.from({ length: 6 }, stat)}
			/>
		</CmsGuideLayout>
	)
}

export default Component
